import { createRouter, createWebHistory } from 'vue-router'

import Home_vaohang from '@/components/Home.vue'
import Lichthidau_vh from '@/components/Lichthidau.vue'
import Kqbd_vh from '@/components/Kqbd'
import Bxh_vh from '@/components/Bxh.vue'
import News_vh from '@/components/New.vue'
import NewsDetail_vh from '@/components/NewDetail.vue'
import Ratio_vh from '@/components/Ratio.vue'

const routes = [
    {
        path: '/',
        name: 'Home_vaohang',
        component: Home_vaohang
    },
    {
        path: '/lich-thi-dau',
        name: 'Lichthidau_vh',
        component: Lichthidau_vh
    },
    {
        path: '/kqbd',
        name: 'Kqbd_vh',
        component: Kqbd_vh
    },
    {
        path: '/bxh',
        name: 'Bxh_vh',
        component: Bxh_vh
    },
    {
        path: '/news',
        name: 'News_vh',
        component: News_vh
    },
    {
        path: '/news/:id',
        name: 'NewsDetail_vh',
        component: NewsDetail_vh
    },
    {
        path: '/ratio',
        name: 'Ratio_vh',
        component: Ratio_vh
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router