<template>
    <div class="container maxwidth_container">
        <div class="row">
            <div class="col-md-8 col-sm-8 col-xs-12 lf_newft">
                <div class="wp_newft_lf">
                    <h2 class="title_new">Tin nổi bật</h2>
                    <div class="item_lf_newft">
                        <div class="img_new_lf">
                            <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                <img src="../assets/images/1.png" alt="">
                            </router-link>
                        </div>
                        <div class="text_lf_new">
                            <p class="name_lfnew"><router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Siêu máy tính dự đoán Ngoại hạng Anh: MU đua top 4, Man City làm điều chưa từng có</router-link></p>
                        </div>
                    </div>
                    <div class="seeNew_new">
                        <router-link to="/news">Xem thêm tin mới +</router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 rg_newft">
                <div class="wp_newft_rg">
                    <h3 class="title_new">Mới nhất</h3>
                    <div class="root_rg_new">
                        <div class="item_rg_newft">
                            <div class="img_new_rg">
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                    <img src="../assets/images/2.png" alt="">
                                </router-link>
                            </div>
                            <div class="text_rg_new">
                                <p class="name_rgnew"><router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Các CLB bóng đá quay cuồng trước mùa giải mới</router-link></p>
                            </div>
                        </div>
                        <div class="item_rg_newft">
                            <div class="img_new_rg">
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                    <img src="../assets/images/3.png" alt="">
                                </router-link>
                            </div>
                            <div class="text_rg_new">
                                <p class="name_rgnew"><router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Báo Indonesia mỉa mai trận giao hữu Việt Nam-Thái Lan</router-link></p>
                            </div>
                        </div>
                        <div class="item_rg_newft">
                            <div class="img_new_rg">
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                    <img src="../assets/images/4.png" alt="">
                                </router-link>
                            </div>
                            <div class="text_rg_new">
                                <p class="name_rgnew"><router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Kết quả thi đấu V-LEAGUE 2024/2025 mới nhất</router-link></p>
                            </div>
                        </div>
                        <div class="item_rg_newft">
                            <div class="img_new_rg">
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                    <img src="../assets/images/5.png" alt="">
                                </router-link>
                            </div>
                            <div class="text_rg_new">
                                <p class="name_rgnew"><router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">BẢNG XẾP HẠNG V-LEAGUE 2024/2025 MỚI NHẤT</router-link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="newUpdate">
            <h4 class="title_new">Bài viết mới cập nhật</h4>
            <div class="row">
                <div class="col-md-3 col-sm-3 co-xs-6 wp_item_newUpdate">
                    <div class="item_newUpdate">
                        <div class="img_newupdate">
                            <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                <img src="../assets/images/6.png" alt="">
                            </router-link>
                        </div>
                        <div class="text_newupdate">
                            <p>
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Đua vô địch La Liga 2024/25: Barcelona sở hữu Yamal - Olmo,...</router-link>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3 co-xs-6 wp_item_newUpdate">
                    <div class="item_newUpdate">
                        <div class="img_newupdate">
                            <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                <img src="../assets/images/7.png" alt="">
                            </router-link>
                        </div>
                        <div class="text_newupdate">
                            <p>
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Dự đoán HLV Ten Hag bị đuổi đầu tiên, Man City vô địch Ngoại hạng Anh 5 năm liền</router-link>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3 co-xs-6 wp_item_newUpdate">
                    <div class="item_newUpdate">
                        <div class="img_newupdate">
                            <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                <img src="../assets/images/8.png" alt="">
                            </router-link>
                        </div>
                        <div class="text_newupdate">
                            <p>
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Messi chưa hẹn ngày trở lại Inter Miami, đi biển cùng vợ yêu và gia đình</router-link>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3 co-xs-6 wp_item_newUpdate">
                    <div class="item_newUpdate">
                        <div class="img_newupdate">
                            <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">
                                <img src="../assets/images/9.png" alt="">
                            </router-link>
                        </div>
                        <div class="text_newupdate">
                            <p>
                                <router-link v-bind:to="{ name: 'NewsDetail_vh', params: { id: 1 } }">Fenerbahce của Mourinho bị loại khỏi Cúp C1: "Người đặc biệt" nói gì về quả 11m?</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Newft_vh',
    data () {
        return {
            
        }
    },
    created () {
    },
    methods: {
        
    }
}
</script>
<style>
</style>
