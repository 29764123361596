<template>
    <footer id="footer" class="footer-wrapper">
        <div class="banner_live_partner">
            <a href="javascript:;"><img src="../assets/images/okvip.png" alt="" style="width:100%;"></a>
        </div>
        <div class="wrapper_fter_end">
            <div class="container ner_responesize">
                <div class="preview_okvip">
                    <div class="img_f8bet">
                        <img src="../assets/images/logof8.png" alt="">
                    </div>
                    <div class="textfooter">
                        <p v-if="!readMore[0]">{{ content.substring(0, 550) + '[...]' }} <span @click="showMore(0)" class="spanmore">Xem thêm</span></p>
                        <p v-if="readMore[0]">{{ content }} <span @click="showLess(0)" class="spanless">Thu gọn</span></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.close_banner', function() {
        $('.banner_fixed_center').css('display', 'none');
    });
    $(document).on('click', '.img_close_parent', function() {
        $('.parent').hide();
        return false;
    });
})
export default {
    name: 'Footer_Vaohang',
    data () {
        return {
            readMore: [false],
            content: 'F8BET là một nhà cái có giấy phép cá cược trực tuyến hợp pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người chơi, F8BET đã và đang khẳng định vị thế của mình trên thị trường game trực tuyến .Với tư cách là một công ty trò chơi trực tuyến trực thuộc Tổng bộ Liên minh OKVIP có trụ sở tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông đảo cung cấp sản phẩm phục vụ chất lượng cao. F8BET đảm bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên thứ ba nào , sử dụng tiêu chuẩn mã hoá dữ liệu ở mức cao nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời được bảo vệ trong môi trường quản lý an toàn đảm bảo không thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an toàn tuyệt đối.',
            linkLive: '',
            linkCuoc: '',
            active: true
        }
    },
    mounted () {
    },
    created () {
        this.getInfoLink()
    },
    methods: {
        showMore(index) {
            this.readMore[index] = true;
        },
        showLess(index) {
            this.readMore[index] = false;
        },
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkLive = response.data.data.list_data.live
                this.linkCuoc = response.data.data.list_data.cuoc
            })
        },
    }
}
</script>
<style>
</style>
