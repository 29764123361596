import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import CKEditor from '@ckeditor/ckeditor5-vue'

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

const app = createApp(App)

var domain_web = 'https://vaohang.tech/api/'
var domain_webf8 = 'https://f8game.vn/'

app.config.globalProperties.url_param = 'vaohang10.tv'

app.config.globalProperties.link_web = 'https://f8game.click/'
app.config.globalProperties.link_webs = 'https://f8game.live/'
app.config.globalProperties.link_websdndk = 'https://vaohang1.one/'

app.config.globalProperties.api_country = 'https://vaohang.dev/result_country.php'
app.config.globalProperties.api_listmatchdate = domain_web + 'danh-sach/tat_ca?sort=day'
app.config.globalProperties.api_listmatchlive = domain_web + 'danh-sach/tat_ca'
app.config.globalProperties.api_blv = domain_web + 'blv'
app.config.globalProperties.api_view = domain_web + 'view'
app.config.globalProperties.api_ltd = 'https://vaohang.dev/result_fixtures.php'
app.config.globalProperties.api_listResult = 'https://keonhacai.1nguon.vip/result.json'

app.config.globalProperties.api_countrybxh = domain_webf8 + 'apilist.php'
app.config.globalProperties.api_listbxh = domain_webf8 + 'apibxh.php'

app.config.globalProperties.api_ratio = domain_webf8 + 'apitilekeo.php'
app.config.globalProperties.api_kqbd = domain_webf8 + 'apiketqua.php'
app.config.globalProperties.api_ltd = domain_webf8 + 'apilichthidau.php'
app.config.globalProperties.api_previewLink = domain_web + 'link'
app.config.globalProperties.api_listMatchApi = domain_web + 'match'
app.config.globalProperties.api_sendInfo = domain_web + 'guess'

app.config.globalProperties.api_listcalendaMatch = 'https://ttbd83.net/business/web_race/match_list'
app.config.globalProperties.link_banner = ''

app.config.globalProperties.api_ltds = domain_web + 'schedule'


app.config.globalProperties.api_bxh = 'https://vaohang.tech/api/league'


var domain_webs = 'https://f8game.click/api/'
app.config.globalProperties.api_register = domain_webs + 'register'
app.config.globalProperties.api_login = domain_webs + 'login'
app.config.globalProperties.api_logout = domain_webs + 'logout'

app.config.globalProperties.link_web = 'https://f8game.click/'

app.config.globalProperties.api_matchCola = domain_web + 'match-live'

app.use(router)
app.use(VueAxios, axios)
app.use(Vue3VideoPlayer)
app.use(Paginate)
app.use(bottomNavigationVue)
app.use(VueSweetalert2)

app.use(Vue3DraggableResizable)
app.use(CKEditor)
app.mount('#app')
