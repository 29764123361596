<template>
    <Header_Vaohang/>
        <router-view :key ='$route.params'/>
    <Footer_Vaohang/>
</template>

<script>
import "./assets/css/style.css"
import Header_Vaohang from './components/Header.vue'
import Footer_Vaohang from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        Header_Vaohang,
        Footer_Vaohang,
    }
}
</script>

<style>
</style>
