<template>
    <div class="container maxwidth_container wrapper_new_list">
        <div class="row wrapper_newend">
            <Newft_vh />
        </div>
    </div>
</template>

<script>
import Newft_vh from '@/components/Newft'
export default {
    name: 'News_vh',
    data () {
        return {
        }
    },
    components: {
        Newft_vh
    },
    created () {
    },
    methods: {
        
    }
}
</script>
<style>
</style>
