<template>
    <div class="container content-margin maxwidth_container ratio_detail">
        <h2 class="title_new">Tỷ lệ - kèo bóng đá trực tuyến hôm nay</h2>
        <div class="root_ratio_home ratio_tyle">
            <Tylekeo_vh />
        </div>
        <div class="row wrapper_newend">
            <Newft_vh />
        </div>
    </div>
</template>

<script>
import Newft_vh from '@/components/Newft'
import Tylekeo_vh from '@/components/Tylekeo.vue'

export default {
    name: 'Ratio_vh',
    data () {
        return {
            listTylekeo: []
        }
    },
    components: {
        Tylekeo_vh,
        Newft_vh
    },
    created () {
        this.getDataTylekeo()
    },
    mounted () {
    },
    beforeUnmount () {
        
    },
    methods: {
        getDataTylekeo () {
            let today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate()
            this.axios.get(this.api_ltds + '?date=' + date).then((response) => {
                this.listTylekeo = response.data.odds
            });
        },
        splitString (str) {
            let strSplit = str.split(',')
            return strSplit
        },
        convertRatio (ratio) {
            let str = ratio;
            let int = Math.floor(ratio/1);
            let mod = ratio%1;
            if(mod > 0.5){
                str = (int+0.5)+'/'+(int+1);
            }
            else if(mod < 0.5){
                str = (int)+'/'+(int+0.5);
            }
            return str;
        },
        formatTimeSramp (str) {
            const timestamp = str * 1000;
            const date = new Date(timestamp);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            // const yyyy = date.getFullYear();
            const hh = String(date.getHours()).padStart(2, '0');
            const ii = String(date.getMinutes()).padStart(2, '0');
            // const ss = String(date.getSeconds()).padStart(2, '0');
            const formattedDate = `${dd}/${mm} ${hh}:${ii}`;
            return formattedDate;
        }
    }
}
</script>

<style scoped>
</style>
