<template>
    <div class="wrapper_new_detail">
        <div class="container maxwidth_container">
            <h2 class="name_new_detail">Real Madrid bị cầm hòa ngày mở màn La Liga: Chỉ mạnh trên giấy, thủ dở công chưa sắc</h2>
            <div class="detailnew_wp">
                <p>Real Madrid khởi đầu mùa giải với chiến thắng 2-0 trước Atalanta ở trận tranh Siêu cúp châu Âu và họ được chờ đợi sẽ “làm cỏ” Mallorca ở vòng 1 La Liga. Quả thực Rodrygo đã mở tỷ số trước cho Madrid chỉ sau 13 phút, nhưng bàn thắng của Vedat Muriqi đầu hiệp 2 khiến đội ĐKVĐ mất điểm ra về.</p>
                <p>Khâu kiểm soát của Real khá tệ khi họ liên tục chuyền sai, ngay trước bàn thua cả Carvajal và Valverde đã để mất bóng. Hàng phòng ngự Real một lần nữa đối mặt với cơn ác mộng trên không mang tên Muriqi, đây không phải lần đầu Muriqi chọc thủng lưới Real từ bóng bổng nhưng ở bàn thua lần này chỉ một mình Rudiger nhận ra sai lầm trong khâu chọn vị trí của đồng đội nhưng quá muộn.</p>
                <p>Hàng thủ Mallorca dù thủng lưới nhưng vẫn chơi với phong độ tốt như mùa trước (lọt lưới ít thứ 5 La Liga) và hậu vệ trái Mojica gây ra vô số thách thức cho Real cả trong tấn công lẫn phòng ngự. Tuy nhiên HLV Ancelotti không có chỉ thị rõ ràng nào cho các học trò để phong tỏa Mojica, cũng có vẻ không chuẩn bị gì để đối phó với Muriqi, còn khi thay người lần đầu thì Carletto lại đưa vào một cầu thủ 38 tuổi.</p>
                <p>Trận đấu này tạo ra những hình ảnh trái ngược từ Real Madrid và dự báo mùa giải La Liga này sẽ không dễ cho đội quân của HLV Carlo Ancelotti như nhiều người nghĩ. Real không bổ sung trung vệ mặc dù thiếu người và hàng phòng ngự trận này của họ đã chơi dưới sức sau khi thi đấu một trận hồi giữa tuần, nhất là Rudiger và Carvajal vốn là những cầu thủ chơi ổn định nhất của đội.</p>
                <p>Mặc dù bộ tứ tấn công đã làm nên bàn mở tỷ số cho Real Madrid, nhưng kỳ thực chỉ Jude Bellingham chơi tốt. Vinicius hơi lạm dụng các pha rê dắt, còn Mbappe không chỉ đói bóng mà còn hay có thói quen từ giữa dạt sang trái và giả vờ pressing khi đội nhà đang phòng ngự. Rodrygo ghi bàn trong một trận đấu mà anh có vẻ bị quá tải khi phải lùi về nhiều để phòng thủ, nhất là khi Mojica liên tục đánh bại Carvajal.</p>
                <p>Có một thực tế không mấy hay ho là dù hàng công Real Madrid nhìn trên giấy có vẻ đẹp, nhưng họ có tận 3 cầu thủ có xu hướng dạt trái và không một ai đá tiền đạo cắm thực thụ. Đá với Atalanta có thể thoải mái hơn vì đội bóng này đá với hàng thủ dâng cao, nhưng mùa trước Real rất hay phải nhờ những khoảnh khắc muộn của Bellingham, hoặc sức mạnh của Joselu để giải quyết những đối thủ phòng ngự số đông ở sân nhà.</p>
                <p>Ngoài ra Toni Kroos giải nghệ đã để lại khoảng trống ở hàng tiền vệ và trận này lẫn hiệp 1 trận gặp Atalanta thể hiện rõ điều đó. Bellingham có xu hướng cầm bóng từ sân nhà đi lên trong khi Valverde chơi tốt hơn khi được cho chạy không bóng. Những Tchouameni và Camavinga không ai chịu kiến thiết tình huống từ sâu bên phần sân nhà, khiến Real hay mất bóng và có lên được bóng thì các pha tấn công cũng dễ bắt bài.</p>
                <p>Đây mới chỉ là 1 trận, Mallorca không phải đối thủ yếu và Real cần thời gian để Mbappe hòa nhập. Tuy nhiên nếu trận này là bản “demo” của lối chơi Real triển khai trong phần còn lại của mùa giải, e rằng họ sẽ chỉ vô địch La Liga trong tưởng tượng.</p>
            </div>
        </div>
        <div class="row wrapper_newend">
            <Newft_vh />
        </div>
    </div>
</template>

<script>
import Newft_vh from '@/components/Newft'
export default {
    name: 'NewsDetail_vh',
    data () {
        return {
        }
    },
    components: {
        Newft_vh
    },
    created () {
    },
    methods: {
        
    }
}
</script>
<style>
</style>
