<template>
    <div class="container content-margin maxwidth_container pading_three_div">
        <div class="wrapper_match_home" v-if="listMatchApi.length > 0">
            <table>
                <thead>
                    <tr>
                        <th style="width: 20%;">Giải đấu</th>
                        <th style="width: 12%;">Thời gian</th>
                        <th style="width: 25%;">Đội nhà</th>
                        <th style="width: 8%;">Tỷ số</th>
                        <th style="width: 25%;">Đội khách</th>
                        <th style="width: 10%;">Xem live</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in listMatchApi" v-bind:key="index">
                        <td style="width: 20%;" class="compeTable">{{ replaceName(item.result.match.competition.slug) }}</td>
                        <td style="width: 12%;">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</td>
                        <td style="width: 25%;">
                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt=""> 
                            {{ replaceName(item.result.match.homeTeam.slug) }}
                        </td>
                        <td class="ratiomtTable" style="width: 8%;">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</td>
                        <td style="width: 25%;">
                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="">
                            {{ replaceName(item.result.match.awayTeam.slug) }} 
                        </td>
                        <td style="width: 10%;">
                            <a :href="link_websdndk + 'live/' + item.result.match.id + '/2/1/' + item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id" target="_blank">Xem live</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="wrapper_bxh_blv">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 left_bxh_home">
                    <DetailBxh_vh />
                </div>
            </div>
        </div>
        <div class="row wrapper_newend">
            <Newft_vh />
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
import Newft_vh from '@/components/Newft'
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on("click",".date_ratio_home label",function() {
        $('.date_ratio_home label').parent().removeClass('active');
        $(this).parent().addClass('active');
    });
    $(document).on("click",".tab_itemlive .tabhome",function() {
        $('.tab_itemlive .tabhome .item_soccer').removeClass('active');
        $(this).find('.item_soccer').addClass('active');
    });
});
export default {
    name: 'Home_vaohang',
    setup() {
		return {
			breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "start"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
		};
	},
    data () {
        return {
            liveRoom: [],
            linkKeo: '',
            linkCuoc: '',
            linkTele: '',
            linkZalo: '',
            linkLive: '',
            listMatchApi: [],
            orther: [],
        }
    },
    components: {
        DetailBxh_vh,
        Newft_vh
    },
    created () {
        this.getInfoLink()
        this.getlistApiMatch()
    },
    mounted () {
    },
    beforeUnmount () {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
                this.linkTele = response.data.data.list_data.telegram
                this.linkZalo = response.data.data.list_data.zalo
                this.linkLive = response.data.data.list_data.live
            })
        },
        getlistApiMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.listMatchApi = response.data.data.filter(d => d.result.match.matchStatus == 2)
            })
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
    }
}
</script>

<style scoped>
</style>
