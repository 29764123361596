<template>
    <div class="wrapper_ratio_home">
        <div class="title_ratio">
            <h3>
                <img src="../assets/images/tl.png" alt="">
                <span>Tỷ lệ kèo</span>
            </h3>
            <ul>
                <li>Tỷ lệ</li>
                <li>Tài xỉu</li>
                <li>1x2</li>
            </ul>
        </div>
        <div class="ratio_home_wrapper">
            <div class="root_rto">
                <div class="wrapper_title_ratio_home">
                    <div class="title_ratio_home">
                        <div class="title_1">trận đấu</div>
                    </div>
                    <div class="title_ratio_home1 border_edittable">
                        <div class="title_2">
                            <div class="div_1">Cả trận</div>
                        </div>
                        <div class="wp_three_table">
                            <div class="one_divRatio">Tỷ lệ</div>
                            <div class="two_divRatio">Tài xỉu</div>
                            <div class="three_divRatio">1x2</div>
                        </div>
                    </div>
                    <div class="title_ratio_home1 title_ratio_home2">
                        <div class="title_2">
                            <div class="div_1">
                                Hiệp 1
                            </div>
                        </div>
                        <div class="wp_three_table">
                            <div class="one_divRatio">Tỷ lệ</div>
                            <div class="two_divRatio">Tài xỉu</div>
                            <div class="three_divRatio">1x2</div>
                        </div>
                    </div>
                </div>
                <div class="wrapper_item_ratio_home">
                    <div class="root_ratio_group" v-for="(item, index) in listTylekeo" :key="index">
                        <div class="tour_leaguename_match">
                            <h3>{{ item.leagueName }}</h3>
                        </div>
                        <div class="item_ratio_home" v-for="(items, indexs) in item.listMatches" :key="indexs">
                            <div class="wrapper_match_ratio">
                                <div class="name_match_ratio">
                                    <p class="textred">{{ items.homeName }}</p>
                                    <p class="vsratio">
                                        <img :src="items.homeIcon" alt="" class="img_chunha">
                                        <img src="../assets/images/vs.png" alt="" class="img_vs">
                                        <img :src="items.awayIcon" alt="" class="img_doikhach">
                                    </p>
                                    <p>{{ items.awayName }}</p>
                                    <div class="date_ratio">{{ formatTimeSramp(items.matchTime) }}</div>
                                </div>
                                <div class="wrapper_ratio_all_match">
                                    <div class="ratio_all_home">
                                        <div class="ratio_all_match ratio_all_match0">
                                            <div class="ratio_1" v-if="typeof items?.handicap != 'undefined'">
                                                <div class="fractionPoint_ratio">
                                                    <p>{{ convertRatio(splitString(items.handicap)[2])}}</p>
                                                </div>
                                            </div>
                                            <div class="ratio_2">
                                                <div class="info_odds" v-if="typeof items?.handicap != 'undefined'">
                                                    <p>{{ splitString(items.handicap)[3] }}</p>
                                                    <p>{{ splitString(items.handicap)[4] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_match border_lf_rg">
                                            <div class="ratio_1">
                                                <div class="fractionPoint_ratio" v-if="typeof items?.overUnder != 'undefined'">
                                                    <p>{{ convertRatio(splitString(items.overUnder)[2]) }}</p>
                                                </div>
                                            </div>
                                            <div class="ratio_2">
                                                <div class="info_odds" v-if="typeof items?.overUnder != 'undefined'">
                                                    <p>{{ splitString(items.overUnder)[3] }}</p>
                                                    <p>{{ splitString(items.overUnder)[4] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_half_match border_rg">
                                            <div class="ratio_3" v-if="typeof items?.europeOdds != 'undefined'">
                                                <p>{{ splitString(items.europeOdds)[2] }}</p>
                                                <p>{{ splitString(items.europeOdds)[3] }}</p>
                                                <p>{{ splitString(items.europeOdds)[4] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ratio_all_home ratio_all_home2">
                                        <div class="ratio_all_match ratio_all_match0">
                                            <div class="ratio_1">
                                                <div class="fractionPoint_ratio" v-if="typeof items?.handicapHalf != 'undefined'">
                                                    <p>{{ convertRatio(splitString(items.handicapHalf)[2])}}</p>
                                                </div>
                                            </div>
                                            <div class="ratio_2">
                                                <div class="info_odds" v-if="typeof items?.handicapHalf != 'undefined'">
                                                    <p>{{ splitString(items.handicapHalf)[3] }}</p>
                                                    <p>{{ splitString(items.handicapHalf)[4] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_match border_lf_rg">
                                            <div class="ratio_1">
                                                <div class="fractionPoint_ratio" v-if="typeof items?.overUnderHalf != 'undefined'">
                                                    <p>{{ convertRatio(splitString(items.overUnderHalf)[2])}}</p>
                                                </div>
                                            </div>
                                            <div class="ratio_2">
                                                <div class="info_odds" v-if="typeof items?.overUnderHalf != 'undefined'">
                                                    <p>{{ splitString(items.overUnderHalf)[3] }}</p>
                                                    <p>{{ splitString(items.overUnderHalf)[4] }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_half_match">
                                            <div class="ratio_3">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tylekeo_vh',
    data () {
        return {
            listTylekeo: []
        }
    },
    components: {
    },
    created () {
        this.getDataTylekeo()
        setInterval(this.getDataTylekeo, 180000);
    },
    mounted () {
    },
    beforeUnmount () {
        
    },
    methods: {
        getDataTylekeo () {
            let today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate()
            this.axios.get(this.api_ltds + '?date=' + date).then((response) => {
                this.listTylekeo = response.data.odds
            });
        },
        splitString (str) {
            let strSplit = str.split(',')
            return strSplit
        },
        convertRatio (ratio) {
            let str = ratio;
            let int = Math.floor(ratio/1);
            let mod = ratio%1;
            if(mod > 0.5){
                str = (int+0.5)+'/'+(int+1);
            }
            else if(mod < 0.5){
                str = (int)+'/'+(int+0.5);
            }
            return str;
        },
        formatTimeSramp (str) {
            const timestamp = str * 1000;
            const date = new Date(timestamp);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            // const yyyy = date.getFullYear();
            const hh = String(date.getHours()).padStart(2, '0');
            const ii = String(date.getMinutes()).padStart(2, '0');
            // const ss = String(date.getSeconds()).padStart(2, '0');
            const formattedDate = `${dd}/${mm} ${hh}:${ii}`;
            return formattedDate;
        }
    }
}
</script>

<style scoped>
</style>
